import React from 'react'
import classNames from 'classnames'
import { PromoCard } from '~/components/PromoCard'
import { SectionContainer } from '~/components/SectionContainer'
import { usePage } from '~/hooks'
import styles from './PromoCardsGrid.module.css'
import { PromoCardsGridProps } from './PromoCardsGrid.types'

export const PromoCardsGrid = ({
  width = 'wide',
  columns,
  rows,
  gridItems,
  promotionId,
  creativeName,
}: PromoCardsGridProps) => {
  const page = usePage()
  const isRTM = page?.brand === 'rtm'
  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames(
        styles.marginTopContainer,
        isRTM && styles.marginBtmContainer
      )}>
      <div
        className={classNames(
          'grid',
          styles.promoGrid,
          isRTM ? 'gap-y-4 gap-x-2 md:gap-x-4' : 'gap-4'
        )}
        style={{
          gridTemplate: `repeat(${rows}, 1fr) / repeat(${columns}, 1fr)`,
        }}>
        {gridItems.map((item, index) => {
          const { mobileLayout, desktopLayout, card } = item
          return (
            <React.Fragment key={index}>
              <div
                className='lg:hidden'
                style={{
                  gridArea:
                    mobileLayout &&
                    `${mobileLayout.y} / ${mobileLayout.x} / ${
                      mobileLayout.y + mobileLayout.height
                    } / ${mobileLayout.x + mobileLayout.width}`,
                  backgroundColor: card.backgroundColor,
                  borderRadius: '7px',
                }}>
                <PromoCard {...card} index={index} />
              </div>
              <div
                className='hidden lg:block'
                style={{
                  gridArea:
                    desktopLayout &&
                    `${desktopLayout.y} / ${desktopLayout.x} / ${
                      desktopLayout.y + desktopLayout.height
                    } / ${desktopLayout.x + desktopLayout.width}`,
                }}>
                <PromoCard
                  {...card}
                  index={index}
                  promotionId={promotionId}
                  creativeName={creativeName}
                />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </SectionContainer>
  )
}
